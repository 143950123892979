@import '~sass/_variables.scss';
#devices {

    .dot {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin-left: 20px;
        background: rgb(149, 149, 149);
    }

    .activated {    
        background: green;
    }

    .transaction-log {
        max-height: 500px;
        overflow: auto;
    }
}



Button:disabled {
    cursor: not-allowed;
    //pointer-events: all !important;
}

/*** Loading Ripple while audio is playing ***/
@keyframes playing-ripple {
    0% {
        top: 96px;
        left: 96px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 18px;
        left: 18px;
        width: 156px;
        height: 156px;
        opacity: 0;
    }
}

.playing-ripple div {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: playing-ripple 1s cubic-bezier(0,0.2,0.8,1) infinite;
}

.playing-ripple div:nth-child(1) {
    border-color: #e90c0c
}

.playing-ripple div:nth-child(2) {
    border-color: #e31c1c;
    animation-delay: -0.5s;
}

.playing-spinner-ripple {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
}
.playing-ripple {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.playing-ripple div { box-sizing: content-box; }
/* generated by https://loading.io/ */

/*** Loading Spinner while audio is not available yet ***/
@keyframes ldio-h8i3rwre11i {
    0% {
        opacity: 1;
        backface-visibility: hidden;
        transform: translateZ(0) scale(0.8,0.8);
    } 100% {
        opacity: 0;
        backface-visibility: hidden;
        transform: translateZ(0) scale(1,1);
    }
}
.ldio-h8i3rwre11i div > div {
    position: absolute;
    width: 17.64px;
    height: 17.64px;
    border-radius: 50%;
    background: #ec3737;
    animation: ldio-h8i3rwre11i 1s linear infinite;
}

.ldio-h8i3rwre11i div:nth-child(1) > div {
    left: 107.5px;
    top: 64.5px;
    animation-delay: -0.875s;
}

.ldio-h8i3rwre11i > div:nth-child(1) {
    transform: rotate(0deg);
    transform-origin: 116.32px 73.32px;
}
.ldio-h8i3rwre11i div:nth-child(2) > div {
    left: 94.5px;
    top: 94.5px;
    animation-delay: -0.75s;
}

.ldio-h8i3rwre11i > div:nth-child(2) {
    transform: rotate(45deg);
    transform-origin: 103.32px 103.32px;
}
.ldio-h8i3rwre11i div:nth-child(3) > div {
    left: 64.5px;
    top: 107.5px;
    animation-delay: -0.625s;
}

.ldio-h8i3rwre11i > div:nth-child(3) {
    transform: rotate(90deg);
    transform-origin: 73.32px 116.32px;
}

.ldio-h8i3rwre11i div:nth-child(4) > div {
    left: 34.5px;
    top: 94.5px;
    animation-delay: -0.5s;
}

.ldio-h8i3rwre11i > div:nth-child(4) {
    transform: rotate(135deg);
    transform-origin: 43.31999999999999px 103.32px;
}

.ldio-h8i3rwre11i div:nth-child(5) > div {
    left: 22.5px;
    top: 64.5px;
    animation-delay: -0.375s;
}

.ldio-h8i3rwre11i > div:nth-child(5) {
    transform: rotate(180deg);
    transform-origin: 31.319999999999993px 73.32px;
}

.ldio-h8i3rwre11i div:nth-child(6) > div {
    left: 34.5px;
    top: 34.5px;
    animation-delay: -0.25s;
}

.ldio-h8i3rwre11i > div:nth-child(6) {
    transform: rotate(225deg);
    transform-origin: 43.31999999999999px 43.31999999999999px;
}

.ldio-h8i3rwre11i div:nth-child(7) > div {
    left: 64.5px;
    top: 22.5px;
    animation-delay: -0.125s;
}

.ldio-h8i3rwre11i > div:nth-child(7) {
    transform: rotate(270deg);
    transform-origin: 73.32px 31.319999999999993px;
}

.ldio-h8i3rwre11i div:nth-child(8) > div {
    left: 94.5px;
    top: 34.5px;
    animation-delay: 0s;
}

.ldio-h8i3rwre11i > div:nth-child(8) {
    transform: rotate(315deg);
    transform-origin: 103.32px 43.31999999999999px;
}

.loadingio-spinner-spin-tdedxv2dob {
    width: 147px;
    height: 147px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
}

.ldio-h8i3rwre11i {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.ldio-h8i3rwre11i div { box-sizing: content-box; }
/* generated by https://loading.io/ */

/*** Listen Modal CSS ***/

.listenModal {
    height: 400px;
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*** Sort Buttons ***/
.sortBtn {
    background-color: transparent;
    border-color: transparent;
    height: 0;
}

.sortBtn:active, .sortBtn:focus {
    outline: 0;
    border: none;
}

.deactivated {
    opacity: 0.3;
}

.searchInput {
    width: 350px;
}