@import '~sass/_variables.scss';
#login {
    height: 100vh;

    .login_wrapper {
        max-width: 600px;
        box-shadow: 2px 2px 2px rgba(0, 0,0,0.4);
        border: solid 1px rgba(0, 0, 0, 0.2);
        background: white;

        .big-logo {
            text-align: center !important;
            margin-top: 15px;
            margin-bottom: 50px;
            color: black;
            font-size: 40px;
            font-weight: bold;
            line-height: 55px;
        }
    }
    
}