@import url("https://use.fontawesome.com/releases/v5.5.0/css/all.css");
@import "variables.scss";
@import '~react-toastify/dist/ReactToastify.css';
@import "utils.scss";

.page {
    height: 100vh;

    .page-main {
        display: flex;
        flex-direction: column;
        height: 100%
    }
}

.customModal {    
    max-width: 500px;
    width: 100%;
}